import React, { useEffect, useState } from 'react';

import  styles from './Samplekiosk.module.css';
import axios from 'axios';
import deploydirectory from './deploydirectory';

const SampleKiosk = ({KioskGenerate,workflows, configlayout, layout, ConfigWorkFlowUpdate, WorkFlowDataUpdate, setLocalWorkFlowValues}) => {



  const [data, setData] = useState(null);

  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
      if (file && file.type === 'image/png') {
      const reader = new FileReader();

      reader.onload = (e) => {
        setImagePreview(e.target.result);
        fetchPNGFile(e.target.result);
      };

      reader.readAsDataURL(file);

    }
  };


  const handleDragOver = (event) => {
  event.preventDefault();
};

const handleDrop = (event) => {
   event.preventDefault();
   const file = event.dataTransfer.files[0];

     if (file && file.type === 'image/png') {
     const reader = new FileReader();

     reader.onload = (e) => {
       setImagePreview(e.target.result);
       fetchPNGFile(e.target.result);

     };
     reader.readAsDataURL(file)

      //GetWorkFlow(text)
   }
 };




    const fetchPNGFile = async (whichthumb) => {
      try {
        // Replace 'your-png-file.png' with the actual path to your PNG file.
        const response = await fetch(whichthumb);
        const blob = await response.blob();
        console.log(blob)
        // Convert the blob to a text string.
        const text = await blob.text();

        GetWorkFlow(text)

        KioskGenerate()

        // Search for the "tEXtprompt" node in the text content.









      } catch (error) {
        console.error('Error reading PNG file:', error);
      }
    }


    const GetWorkFlow = (text) => {

      const indexOfNode = text.indexOf('tEXtprompt');

      if (indexOfNode !== -1) {
        // Extract the content of the "tEXtprompt" node.
      const nodeContent = text.substring(indexOfNode);
      const startIndex = nodeContent.indexOf('{');
      const endIndex = nodeContent.indexOf('"SaveImage"}}') + 13;
      const jsonContent = nodeContent.substring(startIndex, endIndex);
      const jsonObject = JSON.parse(jsonContent);
      updateSettings(jsonObject)

        //console.log(jsonObject[6]['inputs']['text'])
      } else {
        console.log('tEXtprompt node not found in the PNG file.');
      }

    }



  const ShowSamples = (loadedPrompt) => {


    };


    const ThumbClicked = (thumbpath) => {
        //  console.log(thumbpath)

            fetchPNGFile(thumbpath);

      };

const Thumbs =() =>{


  const handleImageClick = (workflow) => {
    // Call the Process() function with the path as an argument

    ConfigWorkFlowUpdate(workflow)
    ThumbClicked(workflow.path)





  };


      return (
   <div>

     {configlayout.layoutsetting[layout][0].upload && false && <div className={styles.dropzoneHolder}>


       <label htmlFor="file" className={styles.dropZone}
       onDrop={handleDrop}
         onDragOver={handleDragOver}
         >


         {imagePreview ? (
           <img src={imagePreview} alt="Selected" className={styles.inputImg}/>
         ) : (
           <div className={styles.dropzoneHolder} style={{ whiteSpace: 'pre-wrap' }}>Drag &amp; Drop
           Workflows</div>
         )}
       </label>
       <input
         id="file"
         type="file"
         accept="image/*"
         onChange={handleFileChange}
         style={{ display: 'none' }}
       />
     </div>}
   {workflows.map((innerArray, index) => (
 <div key={index}>
   {innerArray.map((workflow, subIndex) => (
     <div
        key={subIndex}
        className={styles.thumbholder}
        onClick={() => handleImageClick(workflow)}
         style={{
           backgroundImage: `url(${workflow.path})`,
            backgroundSize: 'cover'
         }}
        >
       {false &&<img className={styles.thumb} src={workflow.path} alt={workflow.title} />}
       <div className={styles.thumbtitle}>{workflow.title}</div>
     </div>
   ))}
 </div>
))}
   </div>
 );
    }


//GetSamples()
function updateSettings(jsonObject){
   WorkFlowDataUpdate(jsonObject)


}
  return (
    <div>
    <Thumbs/>

    </div>
  );
};

export default SampleKiosk;
