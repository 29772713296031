import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Defaultwf.css';

const DefaultWF = ({ UpdateDWF, ConfigWorkFlowUpdate, config, WorkFlowDataUpdate, dataDefaultWorkFlow }) => {
  const [dataWorkFlow, setDataWorkFlow] = useState();


  const HandleReset =()=>{


    //console.log(config.main.defaultworkflow[0][0])
      ConfigWorkFlowUpdate(config.main.defaultworkflow[0][0])
      WorkFlowDataUpdate(dataDefaultWorkFlow)

  }

  useEffect(() => {


if(config){
const defaultWF=config.main.defaultworkflow[0][0]



    const loadPrompt = () => {
      axios
        .get(defaultWF.path)
        .then((response) => {
          const loadedPrompt = response.data;

          //setDataWorkFlow(loadedPrompt);
          UpdateDWF(loadedPrompt)
        })
        .catch((error) => {
          // Handle errors if any
          console.error(error);
        });
    };

    loadPrompt();

    }


  }, [config]);

  return (
    <div className="default" onClick={HandleReset}>
      Basic Workflow
    </div>
  );
};

export default DefaultWF;
