import logo from './logob.svg';
import './Layout01.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Showme from './Showme';
import Sample from './Sample';
import DefaultWF from './DefaultWF';
import DropdownMenu from './DropdownMenu';
import Loading from './Loading';
import ToggleComponent from './ToggleComponent';



function Layout01({configlayout, layout}) {
  const [config, setConfig] = useState(null);
  const [configWorflow, setConfigWorflow] = useState(null);
  const [dataDefaultWorkFlow, setDefaultDataWorkFlow] = useState();
  const [dataWorkFlow, setDataWorkFlow] = useState();


  //New
  const [localPathPort, setLocalPathPort] = useState(null);

  const [prompt, setPrompt] = useState(null);
  const [textPositive, setTextPositive] = useState("");
  const [currentTotal, setCurrentTotal] = useState(0);
  //const postData = { prompt }; //use to be prompt
  const [promptId, setPromptId] = useState(null)
  //const [newseed, setNewSeed]=useState()
  const [randomizeBool, setRandomizeBool]=useState(true)


  //test if workflow met requirements
  const [metRequirement, setMetRequirement]=useState(false)

     //status of job
  const [jobComplete, setJobComplete] = useState(true);
  const [position, setPosition] = useState(null);
  const [currentStatus, setCurrentStatus] = useState(null)
  const [timeoutId, setTimeoutId] = useState(null);


  //done job
  const [imagePath, setImagePath]=useState(null)
  const [downloadName, setDownloadName]=useState(null)

  const [generateState, setGenerateState] = useState(false);


  const [queuePosition, setQueuePosition]= useState(0)

//toggle overlay
const [isOverlayVisible, setOverlayVisible] = useState(false);


const [isAdvancedLayout, setIsAdvancedLayout] = useState(false)

//generatepressed:true, was false but caused selection issue on first run.
  const [localWorkFlowValues, setLocalWorkFlowValues] = useState({
    random: true,
    generatepressed:true,
    seed:0
  });

  const cfgdrop = [
    { icon:'./icons/1.jpg', text: 'Low',  value:2},
    { icon:'./icons/5.jpg', text: 'Strong',  value:3},
    { icon:'./icons/2.jpg', text: 'Max',  value:4},
  ]


      const checkStatus = async () => {
        try {
          const response = await axios.get(localPathPort+'/queuelite');
          const { queue_pending, queue_running } = response.data;

          console.log("Check Status")



          if(timeoutId){
            clearTimeout(timeoutId);
          }


          if (queue_pending.includes(promptId)) {
            const currentPosition = queue_pending.indexOf(promptId);
             setQueuePosition(currentPosition+1)

             setCurrentTotal(0)



            if (currentPosition === 0) {
              // If the prompt is in the first position, check every 10 seconds
               setTimeout(checkStatus, 5000)
               const id = setTimeout(checkStatus, 5000);
            } else {
              // If the prompt is not in the first position, check every 15 seconds
              const id = setTimeout(checkStatus, 15000);
              setTimeout(checkStatus, 15000);
            }
            setPosition(currentPosition);
          } else if (queue_running.some((item) => item.prompt_id === promptId)) {

                setQueuePosition(0)

             if(currentStatus==null){
                 setCurrentStatus("running")

             }else{

              const queueRunning = response.data.queue_running[0];
               const current = queueRunning.current;
              const total = queueRunning.total;



              var percent=Math.floor((current/total)*100)
              setCurrentTotal(percent)



              console.log("job is running", current, total)
              setTimeout(checkStatus, 5000)

             }

          } else {

            console.log("job is done")
           setJobComplete(true)
           setGenerateState(false)
           setCurrentTotal(-1)
             clearTimeout(timeoutId)
            checkHistory()

          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }


      const cleanUpSharedFolder = async () => {


      try {
        const response = await axios.get(localPathPort+'/cleanup');

        const cleanupResponse = response.data;
        console.log("CLEAN UP",cleanupResponse)


        } catch (error) {
          console.error('Error fetching data:', error);
        }

      }


          const checkHistory = async () => {


          try {
            const response = await axios.get(localPathPort+'/history/'+promptId);

            const history = response.data;
              //console.log("allll",promptId)
            //console.log("a",history[promptId].outputs['9'].images)

              showImage(history[promptId].outputs['9'].images[0])
            } catch (error) {
              console.error('Error fetching data:', error);
            }

          }


          const allHistory = async () => {


          try {
            const response = await axios.get(localPathPort+'/history');

            const history = response.data;
            console.log("all history",history)


            } catch (error) {
              console.error('Error fetching data:', error);
            }

          }

        const showImage = (fileInfo) => {

            var temp=localPathPort+"/view?filename="+fileInfo.filename+"&subfolder="+fileInfo.subfolder+"&type="+fileInfo.type
            setImagePath(temp)
            setDownloadName(fileInfo.filename)


          }


///SEND INFO TO COMFYUI
//         <button onClick={handleQueuePrompt}>Queue Prompt</button>



    const handleQueuePrompt = async () => {
      if(generateState){
        try {
        // Assuming setPrompt and updatePostData are asynchronous functions, you can await them
        await setPrompt(dataWorkFlow);
        //await updatePostData(); // Update postData based on the new prompt value
        console.log("Prompt data updated:", { prompt: dataWorkFlow });

        // Now, you can proceed with your axios POST request
        const response = await axios.post(localPathPort + '/prompt', { prompt: prompt });

        // Handle the response if needed
      setPromptId(response.data.prompt_id)
        setCurrentStatus(null)
        } catch (error) {
        // Handle errors if any
        console.error(error);
        }
    }

    };







          useEffect(() => {
            //console.log(currentStatus + "llll")

            if(currentStatus=="running"){
                setTimeout(checkStatus, 3000)
            }
          }, [currentStatus]);

          useEffect(() => {
            // You can put any code here that needs to run when QueuePrompt component mounts or updates.
          }, [prompt]);


          useEffect(() => {
            // You can put any code here that needs to run when QueuePrompt component mounts or updates.
              if(promptId){

                     setJobComplete(false)
                     checkStatus()
              }

          }, [promptId]);



  //NEW END





//Update Selected Workflow
const UpdateDWF=(data)=>{
  setDefaultDataWorkFlow(data)
  setPrompt(data)
   //console.log("ab",data)


  //setDataWorkFlow(data)
   //console.log("a")


}
const WorkFlowDataUpdate = (data) => {
    setDataWorkFlow(data)
    setPrompt(data)
}

useEffect(() => {

  if(dataDefaultWorkFlow){

    //console.log(dataDefaultWorkFlow)
      setDataWorkFlow(dataDefaultWorkFlow)


  }

  }, [dataDefaultWorkFlow]);

const ConfigWorkFlowUpdate = (data) => {
    setConfigWorflow(data)

    //



}
useEffect(() => {

    if(config){

      setConfigWorflow(config.main.defaultworkflow[0][0])
    }

},[config]);



  useEffect(() => {
    // Fetch the data using Axios when the component mounts


        setConfig(configlayout)

        ConfigDream()


      }, [configlayout]);

  const ConfigDream = () => {

      //display thumbnail
      if (config) {
        config.main.workflow.forEach((innerArray) => {
            innerArray.forEach((workflow) => {
              //console.log(workflow.path);
            });
          });


      }
    };


    const TogglePanel = (e) => {

       /*
             const contentDiv = e.currentTarget.nextElementSibling;

             if (contentDiv.style.display === "block") {
            contentDiv.style.display = "none";
              e.currentTarget.classList.remove("collapsible_open")
          } else {
            contentDiv.style.display = "block";
            e.currentTarget.classList.add("collapsible_open")
          }
          */



      };


      const NewGenerate = () => {



          const newseed= NewRandomSeed()

          const newLocalWorkFlowValues = { ...localWorkFlowValues }

          if(localWorkFlowValues.random){

            newLocalWorkFlowValues.seed = newseed
            newLocalWorkFlowValues.generatepressed = true



          }else{
            const newLocalWorkFlowValues = { ...localWorkFlowValues, generatepressed:true }

          }

          setLocalWorkFlowValues(newLocalWorkFlowValues)
          setGenerateState(true)

         cleanUpSharedFolder()

      }



      const NewRandomSeed = () => {
          const newseed=Math.floor(Math.random() * 4294967294) + 1

          return newseed
          //setNewSeed(newseed)
      }



      const UpdateLocalWorkFlow= (value)=>{

      //  setDataWorkFlow(value)

        if(localWorkFlowValues.generatepressed){



          console.log("button clicked")
          const newLocalWorkFlowValues = { ...localWorkFlowValues, generatepressed:false }
          setPrompt(dataWorkFlow)
          handleQueuePrompt()
          setLocalWorkFlowValues(newLocalWorkFlowValues)
        }


        //console.log("AAAA", value)
         setLocalWorkFlowValues(value)

      }


useEffect(() => {

  if(config){
    ConfigDream()

    //set up paths
    var localPathAI=config.setup.server
    setLocalPathPort(localPathAI+config.setup.port)

    console.log("SERVER",localPathAI+config.setup.port)

  }

  }, [config]);

  const handleImageClick = () => {

    fetch(imagePath)
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = downloadName; // Set the desired filename for the downloaded image
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    });
    }

    const  handleToggleButton = () => {


      //const newLocalWorkFlowValues = { ...localWorkFlowValues, random: newStateRandom };

      //setConfig
         //console.log("aAaa", configlayout.layoutsetting[layout][0].upload)
       };


  const  handleRightClick = (e) => {
       e.preventDefault(); // Prevent the right-click context menu
     };

    const toggleOverlayVisibility = () => {
        setOverlayVisible(!isOverlayVisible);
      };

      const  handleAdvToggle = () => {

             const newConfig = { ...configlayout };

            newConfig.layoutsetting[layout][0].upload = !newConfig.layoutsetting[layout][0].upload;
            newConfig.layoutsetting[layout][0].extracontrol = !newConfig.layoutsetting[layout][0].extracontrol;
           console.log("HH", configlayout)
        setConfig(newConfig)





         };

  return (
    <div className="App">
       {isOverlayVisible && <div className="overlay">
         <div className="close" onClick={toggleOverlayVisibility}>&times;</div>
           <div className="finalimage-wrapper">
            <div className="iconsave" onClick={handleImageClick}><img src="/iconsave.png"/></div>
             <img className="finalimageOverlay" src={imagePath} onClick={toggleOverlayVisibility} onContextMenu={handleRightClick}/>
           </div>

       </div>}
        <div className="title"> <img className="logo" src={logo} alt="Logo" /> | &nbsp;&nbsp; Dreams
            {configlayout.layoutsetting.general[0].modetoggle && <div  className="off">
            <ToggleComponent
             handleAdvToggle={handleAdvToggle}
            />
            </div>}
      </div>
        <div className="container">
        {config &&<div className={`flex-item flex-left ${config.layoutsetting[config.layout.layout][0].samples==true?'':'flex-item-hide'}`}>
          <div className={`${config.layoutsetting[config.layout.layout][0].samples==true?'':'hide'}`}>
            <DefaultWF
              UpdateDWF={UpdateDWF}
              ConfigWorkFlowUpdate={ConfigWorkFlowUpdate}
              config={config}
              WorkFlowDataUpdate={WorkFlowDataUpdate}
              dataDefaultWorkFlow={dataDefaultWorkFlow}
              layout={layout}
            />
            <div className="tab">
              <button type="button" className="collapsible collapsible_open" onClick={(event) => TogglePanel(event)}>
                Inspirations
              </button>
              <div className="content">

                  {config && <Sample
                    workflows={config.main.workflow}
                    layout={layout}
                    configlayout={config}
                    ConfigWorkFlowUpdate={ConfigWorkFlowUpdate}
                    WorkFlowDataUpdate={WorkFlowDataUpdate}
                    setLocalWorkFlowValues={setLocalWorkFlowValues}
                  />}

              </div>
            </div>
          </div>

        </div>}


        <div className="flex-center">
          <div className="flex-center">
             {!generateState && imagePath &&

               <div className="finalimage-container">
                 <div className="finalimage-wrapper finalmoveLeft">
                 <div className="iconsave" onClick={handleImageClick}><img src="/iconsave.png"/></div>
                 <img className="finalimage"
                   src={imagePath}
                   onClick={toggleOverlayVisibility}
                   onContextMenu={handleRightClick}
                   />
                 </div>
                  <div className="imageUI"> </div>
               </div>



             }
             {generateState &&
               <div className="lottie">
               <Loading jobComplete={jobComplete} queuePosition={queuePosition} currentTotal={currentTotal}/>
               </div>}
          </div>
        </div>
       <div className="flex-item">
       <div className="flex-right">





       {config && <Showme
         config={config}
         configWorflow={configWorflow}
         dataWorkFlow={dataWorkFlow}
         localWorkFlowValues={localWorkFlowValues}
         UpdateLocalWorkFlow={UpdateLocalWorkFlow}
         generateState={generateState}
         setMetRequirement={setMetRequirement}
         className="show"
         />

       }
       <div className="flex-buffertop flex-left">
         <div className="buttonholder">
            <a href="#" className={`myButton ${generateState==true?'buttonholderOff':''} ${metRequirement==true?'':'buttonholderOff'}`} onClick={NewGenerate}>Generate</a>
            {false && <a href="#" className='myButton' onClick={allHistory}>History Test</a>}
         </div>




       </div>






        </div>


       </div>
      </div>
    </div>
  );
}

export default Layout01;
