import logo from './logob.svg';
import './App.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import deploydirectory from './deploydirectory';
import Layout01 from './Layout01';
import LayoutKiosk from './Layout02';




function App() {

const [config, setConfig] = useState(null);
const [layout, setLayout] = useState(null);

  useEffect(() => {
  axios.get('/config.json')
    //axios.get(deploydirectory+'/config.json')
      .then(response => {
        const loadedConfig = response.data;
        setConfig(loadedConfig)
        setLayout(loadedConfig.layout.layout)


      //  console.log("B",loadedConfig.layoutsetting[loadedConfig.layout.layout][0].samples)

        //ConfigDream()


      })
      .catch(error => {
        // Handle errors if any
        console.error(error);
      });
  }, []);



  return (
    <div className="App">
        {layout=="general" && <Layout01 configlayout={config} layout={layout}/>}
        {layout=="simple" && <Layout01 configlayout={config} layout={layout}/>}
        {layout=="kiosk" && <LayoutKiosk configlayout={config} layout={layout}/>}
    </div>
  );
}

export default App;
