import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Showme.css';
import DropdownMenu from './DropdownMenu';


const Showme = ({config, configWorflow, dataWorkFlow, localWorkFlowValues, generateState, UpdateLocalWorkFlow, setMetRequirement}) => {


const [selectedRatio, setSelectedRatio] = useState(0)
const [selectedCFG, setSelectedCFG] = useState(1)
const [selectedSteps, setSelectedSteps] = useState(1)
const [selectedDenoise, setSelectedDenoise] = useState(2)
const [selectedWidth, setSelectedWidth] = useState()
const [selectedHeight, setSelectedHeight] = useState()
const [baseUnit, setBaseUnit]=useState()

const [localDataWorkFlow,setLocalDataWorkFlow]=useState(dataWorkFlow)

const cfgdrop = [
  { icon:'./icons/5.jpg', text: 'Low',  value:2},
  { icon:'./icons/5.jpg', text: 'Strong', value:8},
  { icon:'./icons/5.jpg', text: 'Max',  value:15},
]

const cfgtip="How closely to follow your image or text prompt. The less close the more the AI will dream on its own."

const ratiodrop = [
  { icon:'./icons/5.jpg', text: '1:1',  value:0},
  { icon:'./icons/3.jpg', text: '2:3',  value:1},
  { icon:'./icons/3.jpg', text: '3:2',  value:2},
]

const stepsdrop = [
  { icon:'./icons/1.jpg', text: 'Quick',  value:10},
  { icon:'./icons/5.jpg', text: 'Great',  value:20},
  { icon:'./icons/3.jpg', text: 'High',  value:30},
]

const denoisedrop = [
  { icon:'./icons/1.jpg', text: 'None',  value:0},
  { icon:'./icons/1.jpg', text: 'Low',  value:.3},
  { icon:'./icons/5.jpg', text: 'Good',  value:.6},
  { icon:'./icons/5.jpg', text: 'High',  value:.8},
  { icon:'./icons/5.jpg', text: 'Full',  value:1},
]


const denoisebuttons = [
  { text: 'Low', className:  'button selected',value:.2},
  { text: 'Medium', className:  'button selected',value:.5  },
  { text: 'Average ', className:  'button selected',value:.8 },
  { text: 'Max ', className:  'button selected', value:1 },
]


//console.log("workflow", localDataWorkFlow)

  const textarea = document.querySelector('.textarea')
  const textareaNegative = document.querySelector('.textareanegative')
  const seedNumber = document.querySelector('.seedHolder')


const GetBaseUnit=(value)=>{
    const tempW=selectedWidth/512
    const tempH=selectedHeight/512

    var newWidth=selectedWidth
    var newHeight=selectedHeight

    var tempbaseunit=512

    if(tempW==1 || tempH==1){
      tempbaseunit=512
    }else if(tempW==2 || tempH==2){
      tempbaseunit=1024
    }else {
      tempbaseunit=1024
    }


    switch (value) {
      case 0:
         newWidth=tempbaseunit
         newHeight=tempbaseunit
        break;
      case 1:
         newWidth=tempbaseunit
         newHeight=tempbaseunit*1.5
        break;
      case 2:
        newWidth=tempbaseunit*1.5
        newHeight=tempbaseunit

        break;
      default:

        break;

    }



    setSelectedWidth(newWidth)
    setSelectedHeight(newHeight)
    setBaseUnit(tempbaseunit)
    setSelectedRatio(value)
    //selectedWidth(newWidth)
    //selectedHeight(newHeight)

}

  /*  const handleNextDenosie = () => {
      setSelectedDenoise((prevSelected) => (prevSelected + 1) % denoisebuttons.length);
    };
    */

   const toggleRandom = () => {
      const newStateRandom = !localWorkFlowValues.random
      const newLocalWorkFlowValues = { ...localWorkFlowValues, random: newStateRandom };
       UpdateLocalWorkFlow(newLocalWorkFlowValues)

       console.log(localWorkFlowValues.random, newStateRandom)
  }

/*ABOVE GETS MOVED TO APP.JS */

function updateSettings(){


  seedNumber.innerHTML = dataWorkFlow[configWorflow.sampler]['inputs']['seed']
  textarea.value=dataWorkFlow[configWorflow.textp]['inputs']['text'];
  textareaNegative.value=dataWorkFlow[configWorflow.textn]['inputs']['text'];



  setSelectedSteps(dataWorkFlow[configWorflow.sampler]['inputs']['steps'])
  setSelectedCFG(dataWorkFlow[configWorflow.sampler]['inputs']['cfg'])
  setSelectedDenoise(dataWorkFlow[configWorflow.sampler]['inputs']['denoise'])

  const tempHeight=dataWorkFlow[5]['inputs']['height']
  const tempWidth=dataWorkFlow[5]['inputs']['width']

    if(tempWidth/tempHeight==1){

        setSelectedRatio(0)

      }else if(tempWidth/tempHeight>1){

        setSelectedRatio(2)
      } else if(tempWidth/tempHeight<1){

          setSelectedRatio(1)
      }


      setSelectedWidth(tempWidth)
      setSelectedHeight(tempHeight)


  const newdataWorkFlow = { ...dataWorkFlow}


  //UpdateLocalWorkFlow(newdataWorkFlow)

//console.log("AAA",configWorflow.requireprompt)

//setLocalDataWorkFlow(newdataWorkFlow)
//UpdateLocalWorkFlow(newdataWorkFlow)
}

useEffect(() => {

if(selectedWidth/selectedHeight ==1){
     console.log("RATIO IS", selectedWidth/selectedHeight)
     setSelectedRatio(0)
}




}, [selectedWidth, selectedHeight])


useEffect(() => {

if(dataWorkFlow){
     console.log("DATA",dataWorkFlow)
     UpdateLocalWorkFlow(dataWorkFlow)
}

}, [])



useEffect(() => {

    if(localWorkFlowValues){

    console.log("show me localwork", dataWorkFlow)
  }




}, [localWorkFlowValues])



function isTextareaNotEmpty(textarea) {
  const trimmedValue = textarea.value.trim();
  return trimmedValue.length > 3;
}
const handleTextareaChange = (event) => {
  //const newText = event.target.value;
     setMetRequirement(isTextareaNotEmpty(textarea))
}


//update the workflow before sending
function updateWorkflow(){


  //console.log("AAAAA", dataWorkFlow)
  if(textarea){


    setMetRequirement(isTextareaNotEmpty(textarea))
    dataWorkFlow[configWorflow.textp]['inputs']['text']=textarea.value
    dataWorkFlow[configWorflow.textn]['inputs']['text']=textareaNegative.value
    dataWorkFlow[configWorflow.sampler]['inputs']['steps']=selectedSteps
    dataWorkFlow[configWorflow.sampler]['inputs']['cfg']=selectedCFG
    dataWorkFlow[configWorflow.sampler]['inputs']['denoise']=selectedDenoise

    dataWorkFlow[configWorflow.prefix]['inputs']['filename_prefix']=config.setup.fileprefix
    dataWorkFlow[configWorflow.size]['inputs']['height']=selectedHeight
    dataWorkFlow[configWorflow.size]['inputs']['width']=selectedWidth

    if(localWorkFlowValues.generatepressed && localWorkFlowValues.random){
      dataWorkFlow[configWorflow.sampler]['inputs']['seed']=localWorkFlowValues.seed
      seedNumber.innerHTML=localWorkFlowValues.seed
    }

    UpdateLocalWorkFlow(localWorkFlowValues)
  }


}

useEffect(() => {

  if(dataWorkFlow!=undefined){
    console.log("JJJJ",dataWorkFlow)
  }

  updateWorkflow()
}, [])



useEffect(() => {

//  console.log("BBB", selectedDenoise)
  if(dataWorkFlow !=undefined){
    updateWorkflow()
  }
}, [selectedDenoise,selectedRatio,selectedCFG,selectedSteps])

useEffect(() => {
  if(localWorkFlowValues){
    updateWorkflow()

  }
}, [localWorkFlowValues])

useEffect(() => {
  if(dataWorkFlow && configWorflow){

    updateSettings()


  }
}, [dataWorkFlow, configWorflow])



const GetValue=(type, value)=>{

  switch (type) {
    case "ratio":
      GetBaseUnit(value)

      break;

    case "cfg":
      setSelectedCFG(value)
      break;
    case "steps":
      setSelectedSteps(value)
      break;

    case "denoise":

      setSelectedDenoise(value)
      break;
    default:
      break;

  }
    //console.log(type, value)


  //  UpdateLocalWorkFlow(newLocalWorkFlowValues)

  //updateWorkflow()
}
  return (
    <div className="showme">


    <div className="fieldtexgt">Positive Prompt</div>
      <div><textarea className="textarea textareanpositive"  onChange={handleTextareaChange}/></div>
      <div className="fieldtexgt fieldneg">Negative Prompt</div>
        <div><textarea className="textarea textareanegative" /></div>

      {prompt && <div>

      </div>}
        {config &&
            <div className={`toolholder ${config.layoutsetting[config.layout.layout][0].extracontrol==true?'':'hide'}`}>

              <div className="ratio">

              <DropdownMenu type="ratio" GetValue={GetValue} label={'Image Ratio:'} defaultIndex={selectedRatio} choices={ratiodrop}/>

              </div>


              <div className="ratio">

              <DropdownMenu type="cfg" GetValue={GetValue} label={'Prompt Weight:'} defaultIndex={selectedCFG} choices={cfgdrop}/>


              </div>


              <div className="ratio">

               <DropdownMenu type="steps" GetValue={GetValue} label={'Quality Steps:'} defaultIndex={selectedSteps} choices={stepsdrop}/>
              </div>
              <div className="ratio">

            <DropdownMenu type="denoise" GetValue={GetValue} label={'Denoise:'} defaultIndex={selectedDenoise} choices={denoisedrop}/>


              </div>

              <div className="ratio">
              <div className="settinglabel"><label htmlFor="myCheckbox" className="label">Random Seed? </label> </div>

               <input className="checkbox" type="checkbox" name="myCheckbox" checked={localWorkFlowValues.random}  onChange={toggleRandom}/>
               <div contentEditable="true" className="seedClass seedHolder"></div>

              </div>


           </div>
       }

    </div>
  );
};

export default Showme;
