import React, { useState, useEffect, useRef } from 'react';
import './DropdownMenu.css';

const DropdownMenu = ({type, GetValue, label, defaultIndex, choices }) => {
  const [selectedIndex, setSelectedIndex] = useState(defaultIndex);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);


function findClosestIndex(target, arr) {
  let closestIndex = 0;
  let closestDifference = Math.abs(target - arr[0].value);

  for (let i = 1; i < arr.length; i++) {
    const difference = Math.abs(target - arr[i].value);

    if (difference < closestDifference) {
      closestIndex = i;
      closestDifference = difference;
    }
  }
  return closestIndex;
}

const tempIndex=findClosestIndex(defaultIndex,choices)



useEffect(() => {

    setSelectedIndex(tempIndex)


}, [tempIndex])



  const handleOptionClick = (index) => {
    setSelectedIndex(index);
    setIsOpen(false);

    GetValue(type, choices[index].value)
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (isOpen && dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen]);

  const optionElements = choices.map((choice, index) => (


    <div
      key={index}
      className="option"
      onClick={() => handleOptionClick(index)}
    >
      <img src={choice.icon} alt={choice.text} />
      {choice.text}
    </div>
  ));

  return (
    <div ref={dropdownRef} className="dropdown-menu">
      <div className="selected-option" onClick={() => setIsOpen(!isOpen)}>

      <div className="droplabel">{label}</div>
        <img
          src={choices[selectedIndex].icon}
          alt={choices[selectedIndex].text}
        />
        {choices[selectedIndex].text}
      </div>
      {isOpen && (
        <div>
        <div className="options">
          {optionElements}

        </div>

          </div>

      )}


    </div>
  );
};

export default DropdownMenu;
