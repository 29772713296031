import React from 'react';
import Lottie from 'lottie-react';
import './Loading.css';
import animationData from './lottie/animation_2.json';

function LottieAnimation({jobComplete, queuePosition,currentTotal,color}) {


  var RenderSaying= function(){
    var saying="working on it"
    if(currentTotal>30 && currentTotal<50){
      saying="ready shortly."
    }

    if(currentTotal>40 && currentTotal<70){
      saying="almost ready!"
    }

    if(currentTotal>70 && currentTotal<95){
      saying="finalizing."
    }

    if(currentTotal>95){
      saying="a few more seconds."
    }

    return saying


  }
  return (
    <div className="loadingHolder">
      <Lottie animationData={animationData}
      style={{ width: '300px', height: '300px'}}
      />



              <div className={`stats ${jobComplete ? 'hidestat' : ''} ${color=="white"? 'statswhite': ''}`}>
                   {queuePosition>0 &&<div className="statlabel">Waiting, for {queuePosition} job(s).</div>}
                   {queuePosition==0 && currentTotal<=0 &&<div className="statlabel">Preprocessing...</div>}
                   {queuePosition==0 && currentTotal>0 && <div className="statlabel">Creating... {RenderSaying()} </div>}

               </div>


    </div>
  );
}

export default LottieAnimation;
