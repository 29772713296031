import React, { useState } from 'react';


import './ToggleComponent.css';


const ToggleComponent = ({handleAdvToggle}) => {


  const [isAdvanced, setIsAdvanced] = useState(false);
       //setIsAdvancedLayout(isAdvanced)

  const handleChange = () => {
    setIsAdvanced(!isAdvanced);
    handleAdvToggle()
  };

  return (
    <div>
        <label className="switch">
          <input type="checkbox"
          checked={isAdvanced}
          onChange={handleChange}
          />
          <span className="slider round"></span>
        </label>


      <p className="sliderText">
        Mode: {isAdvanced ? 'Advanced' : 'Simple'}
      </p>
    </div>
  );
};

export default ToggleComponent;
